.title{
  color:orangered;
  font-weight: 900;
  font-size: 50px;
}
footer .nav-link, footer h5, footer p{
  color:#ffffff !important;
}
.card img{
  height: 200px;
  object-fit: contain;
}